import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/Modal.css'; 

const Modal = ({ isOpen, onClose, onConfirm, prompt, confirmText }) => {
  if (!isOpen) return null;

  const modalContent = (
    <>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="confirmation-dialog">
        <p>{prompt}</p>
        <div className="modal-buttons">
        <button onClick={onConfirm}>{confirmText}</button> {/* Use onConfirm directly */}
        <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById('modal-root')
  );
};

export default Modal;
