import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { KitProvider, KitConfig, getKitConnectWallets } from "@0xsequence/kit";
import { KitWalletProvider } from "@0xsequence/kit-wallet";
import {
  google,
  apple,
  discord,
  twitch,
  email,
  sequence,
  metamask,
  walletConnect,
  coinbaseWallet,
} from "@0xsequence/kit-connectors";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  polygon,
  mainnet,
  polygonZkEvm,
  base,
  arbitrum,
  avalanche,
  optimism,
  arbitrumNova,
  bsc,
  gnosis,
} from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

import Background from "./components/Background";
import Homepage from "./components/Homepage";
import Footer from "./components/Footer";
import OfflinePage from "./components/OfflinePage";
import AuthPage from "./components/AuthPage";
import RedirectAuth from "./components/RedirectAuth";
import Sign from "./components/Sign";
import config from "./config.json";

import AddBot from "./components/links/AddBot";
import DiscordServer from "./components/links/DiscordServer";
import Terms from "./components/links/Terms";
import Policy from "./components/links/Policy";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    polygon,
    mainnet,
    polygonZkEvm,
    base,
    arbitrum,
    avalanche,
    optimism,
    arbitrumNova,
    bsc,
    gnosis,
  ],
  [publicProvider()]
);

const connectors = getKitConnectWallets([
  google({
    chains,
    options: {
      defaultNetwork: 137,
      projectAccessKey: config.projectAccessKey,
    },
  }),
  apple({
    chains,
    options: {
      defaultNetwork: 137,
      projectAccessKey: config.projectAccessKey,
    },
  }),
  discord({
    chains,
    options: {
      defaultNetwork: 137,
      projectAccessKey: config.projectAccessKey,
    },
  }),
  twitch({
    chains,
    options: {
      defaultNetwork: 137,
      projectAccessKey: config.projectAccessKey,
    },
  }),
  email({
    chains,
    options: {
      defaultNetwork: 137,
      projectAccessKey: config.projectAccessKey,
    },
  }),
  sequence({
    chains,
    options: {
      defaultNetwork: 137,
      projectAccessKey: config.projectAccessKey,
    },
  }),
  metamask({
    chains,
  }),
  walletConnect({
    chains,
    options: {
      projectId: "1770044713fb1ff73932554abcceab36",
    },
  }),
  coinbaseWallet({
    chains,
    options: {
      appName: "haggle.gg",
    },
  }),
]);

const kitConfig: KitConfig = {
  projectAccessKey: config.projectAccessKey,
  position: "center",
  defaultTheme: "light",
  signIn: {
    projectName: "haggle.gg",
    showEmailInput: true,
    socialAuthOptions: ["google", "apple", "discord", "twitch"],
    walletAuthOptions: [
      "sequence",
      "metamask",
      "wallet-connect",
      "coinbase-wallet",
    ],
  },
};

const wagmiClient = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
  connectors,
});

function App() {
  return (
    <WagmiConfig config={wagmiClient}>
      <KitProvider config={kitConfig}>
        <KitWalletProvider>
          <Router>
            <div>
              <Background />
              <Routes>
                <Route path="/" element={<Homepage config={config} />} />
                <Route path="/sign" element={<Sign config={config} />} />
                <Route
                  path="/offline"
                  element={<OfflinePage config={config} />}
                />
                <Route path="/auth" element={<AuthPage config={config} />} />
                <Route
                  path="/auth/redirect"
                  element={<RedirectAuth config={config} />}
                />
                  <Route
                  path="/bot"
                  element={<AddBot config={config} />}
                />
                <Route
                  path="/server"
                  element={<DiscordServer config={config} />}
                />
                <Route
                  path={config.termsUrl}
                  element={<Terms config={config} />}
                />
                <Route
                  path={config.policyUrl}
                  element={<Policy config={config} />}
                />
              </Routes>
              <Footer />
            </div>
          </Router>
        </KitWalletProvider>
      </KitProvider>
    </WagmiConfig>
  );
}

export default App;
