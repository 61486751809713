// Import statements should be at the very top
import { Buffer } from "buffer";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.tsx"; // Assuming 'App' is either a '.js' or '.jsx' file, or your project is set up to handle '.tsx' without specifying the extension
import reportWebVitals from "./reportWebVitals";

// Setting Buffer on the window object right after imports
window.Buffer = Buffer;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
    <App />
  //</React.StrictMode>
);

reportWebVitals();
