import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import "../styles/Global.css";
import "../styles/HaggleProfile.css";
import "../styles/Homepage.css";

function OfflinePage({ config }) {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    setIsVisible(true);

    const intervalId = setInterval(() => {
      navigate("/");
    }, 30000); 

    return () => clearInterval(intervalId); 
  }, [navigate]); 

  return (
    <div
      className={`content-container ${isVisible ? "visible" : ""}`}
      style={{
        animationName: "fastBounceIn",
        animationDuration: "0.5s",
        animationFillMode: "both",
      }}
    >
      <div
        className="display-image haggle-image"
        style={{
          backgroundImage: `url(${config.hagglelogo})`,
          filter: "grayscale(80%)",
        }}
      />
      <div className="title haggle">haggle.gg</div>
      <p className="tagline">The web3 barter bot.</p>
      <div className="checkbox-container">
        Server is offline. Please try again later.
      </div>
    </div>
  );
}

export default OfflinePage;
