// authService.js
export const checkAuthentication = async (
  config,
  navigate,
  getAndApplyStyles
) => {
  const url = `${config.serverUrl}/validate-session`;

  try {
    const response = await fetch(url, { credentials: "include" });
    if (!response.ok) {
      if (response.status === 401) {
        navigate("/auth");
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } else {
      const data = await response.json();
      if (data.authenticated) {
        getAndApplyStyles();
      } else {
        navigate("/auth");
      }
    }
  } catch (error) {
    console.error("Server is offline. Please try again later.");
    navigate("/offline");
  }
};
