import React, { useEffect } from 'react';

function Terms({ config }) {
  useEffect(() => {
    window.location.href = `${config.clientUrl}${config.termsUrl}.pdf`;
  }, [config]);

  return (
    <div>Redirecting...</div>
  );
};

export default Terms;