import { sequence } from "0xsequence";
import { NftSwapV4 } from '@traderxyz/nft-swap-sdk';
const { ethers } = require('ethers');

const TransactionUtils = {
  async signTransaction(userId, config, orderData, setTrxState) {
    const wallet = sequence.initWallet(config.projectAccessKey, {
      defaultNetwork: orderData.object.chainId,
    });

    try {
      const signer = wallet.getSigner();
      const signerAddress = wallet.getAddress();
      const provider = wallet.getProvider();
      const chainId = wallet.getChainId();
      const nftSwapSdk = new NftSwapV4(provider, signer, chainId);

      if (orderData.maker_id === userId) {
        //const makerBundle = orderData.direction ? orderData.object.receiveBundle : orderData.object.sendBundle;
        const makerBundle = orderData.object.receiveBundle;
        const unsignedOrder = await this.createAndSignOrder(config.serverUrl, nftSwapSdk, orderData, signerAddress, setTrxState);

        //const approvalStatus = await this.checkAndApprove(nftSwapSdk, makerBundle, signerAddress);
        const approvalStatus = await nftSwapSdk.loadApprovalStatusForOrder(
          unsignedOrder,
          "MAKER" // or 'TAKER'
        );
        console.log(approvalStatus)
        if (!approvalStatus.contractApproved) {
          await this.approveToken(nftSwapSdk, makerBundle, signerAddress);
        }

        console.log(unsignedOrder)
        const signedOrder = await this.signOrder(nftSwapSdk, unsignedOrder, signerAddress);

        if (signedOrder) {
        if (signedOrder !== "hasBalance") {
          if (signedOrder !== "canOrderBeFilled") {
            await this.updateTransaction(config.serverUrl, orderData, signedOrder, setTrxState);
        } else {
          console.log("Order cannot be made.");
          setTrxState({
            id: orderData.id,
            signSuccess: false,
            returnMsg: `Order cannot be made.`,
          });
        } 
      } else {
        console.log("Insufficient item or token in wallet");
        setTrxState({
          id: orderData.id,
          signSuccess: false,
          returnMsg: `Insufficient ${orderData.object.receiveBundle[0].type} in wallet.`,
        });
      }
    } else {
      console.log("Refused signing");
    }

      } else if (orderData.taker_id === userId){

        orderData.signed_order.signature.r = "0x0000000000000000000000000000000000000000000000000000000000000000"
        orderData.signed_order.signature.s = "0x0000000000000000000000000000000000000000000000000000000000000000"

        let check;
        try {
          check = await nftSwapSdk.checkOrderCanBeFilledTakerSide(orderData.signed_order, signerAddress);
    
          if (check.hasBalance) {
            if (!check.canOrderBeFilled) {
              //console.log("Fill flag");
            }

            console.log(orderData.signed_order);

            const displayPoints = orderData.object.feeData?.points || "none";
            if (orderData.object.royalties) {
              console.log("Potential points:", displayPoints);
            }

            try {
              await this.fillOrKill(orderData.signed_order, signerAddress, config.serverUrl, orderData, setTrxState, nftSwapSdk);
            } catch {
              // Wait 1 second
              console.log(`${orderData.object.sendBundle[0].type} needs approval.`);
              await new Promise(resolve => setTimeout(resolve, 800)); // 1000 milliseconds = 1 second
            
              try {
              // After waiting, continue with the rest of the code
              const takerBundle = orderData.object.sendBundle;
              const approvalStatus = await this.checkAndApprove(nftSwapSdk, takerBundle, signerAddress);
              //const approvalStatus = await nftSwapSdk.loadApprovalStatusForOrder(
                //  orderData.signed_order,
                //  "TAKER"
                //);
              console.log(approvalStatus);
            
              if (!approvalStatus.contractApproved) {
                await this.approveToken(nftSwapSdk, takerBundle, signerAddress);
              }
              
              
              await this.fillOrKill(orderData.signed_order, signerAddress, config.serverUrl, orderData, setTrxState, nftSwapSdk);
              } catch {
                setTrxState({
          id: orderData.id,
          signSuccess: false,
          returnMsg: `Canceled ${orderData.object.sendBundle[0].type} approval.`,
        });
              }
            }            

          } else {
            console.log("Insufficient item or token in wallet");
            setTrxState({
              id: orderData.id,
              signSuccess: false,
              returnMsg: `Insufficient ${orderData.object.sendBundle[0].type} in wallet.`,
            });
          }
        } catch (error) {
          console.error("Error in order execution:", error);
        }
      }
    } catch (error) {
      console.error("Error in signTransaction:", error);
      setTrxState({
        id: null,
        signSuccess: false,
        returnMsg: "Error during transaction signing",
      });
    }
  },

  async fillOrKill(signed_order, signerAddress, serverUrl, orderData, setTrxState, nftSwapSdk) {
    //const fillTx = await nftSwapSdk.fillBuyNftOrderWithoutApproval(signedOrderArray);
    const fillTx = await nftSwapSdk.fillSignedOrder(signed_order, signerAddress);
    const fillTxReceipt = await nftSwapSdk.awaitTransactionHash(fillTx.hash);
    console.log(`🎉 🥳 Order filled. TxHash: ${fillTxReceipt.transactionHash}`);
        
    const trxHash = [{ finHash: fillTxReceipt.transactionHash }];
    await this.completeTransaction(serverUrl, orderData, trxHash, setTrxState);
  },

  async cancelTransaction(userId, config, orderData, setTrxState) {
    const wallet = sequence.initWallet(config.projectAccessKey, {
      defaultNetwork: orderData.object.chainId,
    });

    try {
      const signer = wallet.getSigner();
      const provider = wallet.getProvider();
      const chainId = wallet.getChainId();
      const nftSwapSdk = new NftSwapV4(provider, signer, chainId);

      if (orderData.maker_id === userId) {
        const orderType = orderData.object.sendBundle[0].type === "ERC20" ? orderData.object.receiveBundle[0].type : orderData.object.sendBundle[0].type;
        const result = await nftSwapSdk.cancelOrder(orderData.signed_order.nonce, orderType);

        if (result.blockNumber > 0) {
          await this.deleteTransaction(config.serverUrl, orderData, setTrxState);
        } else {
          console.log("Error canceling order.");
          setTrxState({
            id: orderData.id,
            signSuccess: false,
            returnMsg: `Error signing #${orderData.id}.`,
          });
        }
      } else {
        console.log("Wrong account");
        setTrxState({
          id: null,
          signSuccess: false,
          returnMsg: "Wrong account",
        });
      }
    } catch (error) {
      console.error("Error in cancelTransaction:", error);
      setTrxState({
        id: null,
        signSuccess: false,
        returnMsg: "Error during transaction canceling",
      });
    }
  },

  async checkAndApprove(nftSwapSdk, bundle, signerAddress) {
    // accommodate potential fees
    const erc20Check = (bundle.length > 0 && bundle[0].type === "ERC20") ? 2 : 1;
    const amount = ethers.BigNumber.from(bundle[0].amount).mul(erc20Check);
    
    return await nftSwapSdk.loadApprovalStatus(bundle[0], signerAddress, undefined, {
      approvalAmount: amount, // Pass as string if that's what the SDK requires
    });
  },

  async approveToken(nftSwapSdk, bundle, signerAddress) {
// buffer the ERC20 to accommodate potential fees
const erc20Check = (bundle.length > 0 && bundle[0].type === "ERC20") ? 4 : 1;
const amount = ethers.BigNumber.from(bundle[0].amount).mul(erc20Check);

let approvalTx;
if (erc20Check === 1) {
    approvalTx = await nftSwapSdk.approveTokenOrNftByAsset(bundle[0], signerAddress);
} else {
  console.log("Set approval with buffer for: ", amount.toString());
  approvalTx = await nftSwapSdk.approveTokenOrNftByAsset(bundle[0], signerAddress, undefined, {
    approvalAmount: amount, // Pass as string if that's what the SDK requires
  });
}
    const approvalTxReceipt = await approvalTx.wait();
    console.log(`Approved ${bundle[0].tokenAddress} contract (txHash: ${approvalTxReceipt.transactionHash})`);
  },

  async createAndSignOrder(serverUrl, nftSwapSdk, orderData, signerAddress, setTrxState) {

    let options = {};
    if (orderData.object.royalties) {
      try {
        const feeData = await this.fetchFeeData(serverUrl, orderData.object, orderData.id, setTrxState);
        
        if (feeData.recipient && feeData.amount) {
        options = {
          fees: [
            { 
              recipient: feeData.recipient,
              amount: feeData.amount,
            }
          ]
        };
        // feeData: "0x0000000000000000000000000000000000000000000000000000000000000000"
        console.log("Potential points:", feeData.points)
      } else {
        console.log("royaltyInfo function not supported.")
      }
      } catch (error) {
        console.error("Failed to fetch fee data:", error);
      }      
    } else {
      // {expiry: "86400000"};
    }

    // nftSwapSdk.buildOrder();
    const directionBuySell = orderData.direction ? "buy" : "sell";
    if (orderData.direction) {
      return nftSwapSdk.buildNftAndErc20Order(orderData.object.sendBundle[0], orderData.object.receiveBundle[0], directionBuySell.toString(), signerAddress, options);
    } else {
      return nftSwapSdk.buildNftAndErc20Order(orderData.object.receiveBundle[0], orderData.object.sendBundle[0], directionBuySell.toString(), signerAddress, options);
    }
  },

  async signOrder(nftSwapSdk, order, signerAddress) {
    let check;
    try {
      check = await nftSwapSdk.checkOrderCanBeFilledMakerSide(order);
      //console.log(check)

      if (check.hasBalance) {
        if (check.canOrderBeFilled) {
            return await nftSwapSdk.signOrder(order, signerAddress);
        } else {
          return "canOrderBeFilled";
        }
      } else {
        return "hasBalance";
      }
    } catch (error) {
      console.error("Error in checking and signing:", error);
    }
  },

  async updateTransaction(serverUrl, orderData, signedOrder, setTrxState) {
    const url = `${serverUrl}/update-txn`;
    const body = JSON.stringify({ orderData, signedOrder });

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        console.log("Success")
        setTrxState({
          id: orderData.id,
          signSuccess: true,
          returnMsg: `Transaction #${orderData.id} signed!`,
        });
      } else {
        throw new Error("Error Signing");
      }
    } catch (error) {
        console.error("Error updating transaction:", error);
        setTrxState({
          id: orderData.id,
          signSuccess: false,
          returnMsg: `Error signing #${orderData.id}.`,
        });
      }
    },

    async deleteTransaction(serverUrl, orderData, setTrxState) {
      const url = `${serverUrl}/delete-txn`;
      const body = JSON.stringify({ orderData });
  
      try {
        const response = await fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        if (data.success) {
          console.log("Success")
          setTrxState({
            id: orderData.id,
            signSuccess: true,
            returnMsg: `Transaction #${orderData.id} canceled.`,
          });
        } else {
          throw new Error("Error Signing");
        }
      } catch (error) {
          console.error("Error canceling transaction:", error);
          setTrxState({
            id: orderData.id,
            signSuccess: false,
            returnMsg: `Error canceling #${orderData.id}.`,
          });
        }
      },

      async fetchFeeData(serverUrl, orderObject, orderId, setTrxState) {
        const url = `${serverUrl}/fetch-fee`;
        const body = JSON.stringify({ orderObject, orderId });
    
        try {
          const response = await fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: body,
          });
    
          if (!response.ok) {
            const errorText = await response.text();
            let errorMessage = `${errorText}`;
    
            switch (response.status) {
              case 400:
                errorMessage = "Error fetching fee data: The request had missing or invalid details.";
                break;
              case 404:
                errorMessage = "Error fetching fee data: Could not find royalty information for the provided details.";
                break;
              default:
                errorMessage = "Error fetching fee data: An unexpected error occurred on the server.";
            }
            throw new Error(errorMessage);
          }

          return await response.json();
        } catch (error) {
          //console.error("Error fetching fee data:", error);
          //setTrxState({
          //  id: orderId,
          //  signSuccess: false,
          //  returnMsg: 'Error fetching fee data.',
          //});
        }
    },    

    async completeTransaction(serverUrl, orderData, trxHash, setTrxState) {
      const url = `${serverUrl}/complete-txn`;
      const body = JSON.stringify({ orderData, trxHash });
  
      try {
        const response = await fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        if (data.success) {
          console.log("Success")
          setTrxState({
            id: orderData.id,
            signSuccess: true,
            returnMsg: `Transaction #${orderData.id} executed!`,
          });
        } else {
          throw new Error("Error Signing");
        }
      } catch (error) {
          console.error("Error updating transaction:", error);
          setTrxState({
            id: orderData.id,
            signSuccess: false,
            returnMsg: `Error signing #${orderData.id}.`,
          });
        }
      }
};

export default TransactionUtils;
