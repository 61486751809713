import React, { useEffect } from 'react';

function AddBot({ config }) {
  useEffect(() => {
    window.location.href = `${config.addHaggleBot}`;
  }, [config]);

  return (
    <div>Redirecting...</div>
  );
};

export default AddBot;