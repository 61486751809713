import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useOpenConnectModal } from "@0xsequence/kit";
import { useAccount } from "wagmi";
import Confetti from "react-dom-confetti";

import { checkAuthentication } from "../functions/authService";
import DeleteAccount from "./DeleteAccount";
import TransactionUtils from "../functions/TransactionUtils";
import WarningMsg from "./WarningMsg";
import "../styles/Global.css";
import "../styles/UserProfile.css";
import "../styles/DeleteProfile.css";
import "../styles/Homepage.css";
import "../styles/Textbox.css";

function Sign({ config }) {
  const navigate = useNavigate();
  const [authCheckInProgress, setAuthCheckInProgress] = useState(true);
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const [userProfile, setUserProfile] = useState({
    userId: "",
    avatarHash: "",
    username: "",
    isVisible: false,
  });
  const { search } = useLocation();
  const orderId = new URLSearchParams(search).get("id");
  const { setOpenConnectModal } = useOpenConnectModal();
  const { isConnected } = useAccount();

  const applyStyles = useCallback((userId, avatarHash, username) => {
    setUserProfile({ userId, avatarHash, username, isVisible: true });
  }, []);
  const [orderNumber, setOrderNumber] = useState("");

  const getAndApplyStyles = useCallback(() => {
    const userProfileUrl = `${config.serverUrl}/user-profile`;

    fetch(userProfileUrl, { credentials: "include" })
      .then((response) => response.json())
      .then((userProfile) => {
        applyStyles(
          userProfile.userId,
          userProfile.avatarHash,
          userProfile.username
        );
      })
      .catch((error) => console.error("Error fetching user profile:", error));
  }, [config.serverUrl, applyStyles]);

  const authCheckDoneRef = useRef(false);
  const inputRef = useRef();

  useEffect(() => {
    if (!authCheckDoneRef.current) {
      if (orderId) {
        localStorage.setItem("hasOrderId", orderId.toString());
      }
      checkAuthentication(config, navigate, getAndApplyStyles).finally(() => {
        setAuthCheckInProgress(false); // Auth check is done
      });
      authCheckDoneRef.current = true;
    }
  }, [orderId, config, navigate, getAndApplyStyles]);

  useEffect(() => {
    if (!authCheckInProgress && userProfile.isVisible) {
      inputRef.current && inputRef.current.focus();
    }
  }, [authCheckInProgress, userProfile.isVisible]);

  const [tradeStatus, setTradeStatus] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [buttonState, setButtonState] = useState("waiting");

  const loadOrderDetails = useCallback(
    (orderId) => {
      const url = `${config.serverUrl}/sign?id=${orderId}`;

      fetch(url, { credentials: "include" })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            setTradeStatus(data.message);
          }
          if (data.order) {
            setOrderData(data.order);
            setButtonState(data.buttonState || "ready");
          } else {
            setButtonState(data.buttonState || "error");
          }
        })
        .catch((error) => {
          console.error("Error loading order:", error);
          setTradeStatus("Error loading order.");
          setButtonState("error");
        });
    },
    [config.serverUrl]
  );

  // Event handler for when the input changes
  const handleInputChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/gi, "");
    setOrderNumber(value.startsWith("#") ? value : `#${value}`);
  };

  // Event handler for key down
  const handleKeyDown = (event) => {
    const inputId = orderNumber.replace(/^#/, "").trim();

    if (event.key === "Enter" && inputId.length > 0) {
      loadOrderDetails(inputId);
    }
  };

  useEffect(() => {
    if (orderId) {
      loadOrderDetails(orderId);
    } else {
      const storedOrderId = localStorage.getItem("hasOrderId");
      if (storedOrderId !== null) {
        // Convert the retrieved value back to a number
        const hasOrderId = parseInt(storedOrderId, 10);
        loadOrderDetails(hasOrderId);
        localStorage.removeItem("hasOrderId");
      }
    }
  }, [orderId, loadOrderDetails]);

  const handleConnect = async () => {
    try {
      setOpenConnectModal(true);
    } catch (e) {
      console.error("Failed to connect wallet:", e);
    }
  };

  // Blockchain time baby
  const [trxState, setTrxState] = useState({
    id: null,
    signSuccess: null,
    returnMsg: null,
  });

  const prepareTransaction = useCallback(async () => {
    try {
      await TransactionUtils.signTransaction(
        userProfile.userId,
        config,
        orderData,
        setTrxState
      );
    } catch (e) {
      console.error("Failed to sign transaction:", e);
    }
  }, [userProfile, config, orderData]);

 // Function to handle user confirmation from the warning message
 const handleUserConfirmation = async (confirm) => {
  localStorage.setItem("confirmedWarning", String(true));
  setShowWarningMsg(false); // Close the warning message modal
  if (confirm) {
    prepareTransaction(); // Proceed with signing
  }
};

  const trySignPrompt = async () => {
    if (isConnected) {
      const alreadyConfirmedWarning = localStorage.getItem("confirmedWarning");
      if (alreadyConfirmedWarning === 'true') {
        prepareTransaction();
      } else {
        setShowWarningMsg(true);
      }
    } else {
      handleConnect();
    }
  };

  const cancelTransaction = useCallback(async () => {
    try {
      await TransactionUtils.cancelTransaction(
        userProfile.userId,
        config,
        orderData,
        setTrxState
      );
    } catch (e) {
      console.error("Failed to cancel order:", e);
    }
  }, [userProfile, config, orderData]);

  const tryCancelPrompt = async () => {
    if (isConnected) {
      cancelTransaction();
    } else {
      handleConnect();
    }
  }; 

  useEffect(() => {
    if (trxState.signSuccess !== null) {
      // Update tradeStatus and buttonState based on trxState.signSuccess
      if (trxState.signSuccess) {
        setTradeStatus(trxState.returnMsg);
        setButtonState("waiting");
      } else {
        setTradeStatus(trxState.returnMsg);
        setButtonState("error");
      }
    }
  }, [trxState.signSuccess, trxState.returnMsg]);

  const pinata = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  const confettiActive = trxState.signSuccess && trxState.signSuccess !== null;

  return (
    <div
      className={`content-container ${userProfile.isVisible ? "visible" : ""}`}
    >
      <Confetti active={confettiActive} config={pinata} />
      {userProfile.userId && (
        <div className="profile-container">
          <div
            className="display-image profile-image"
            style={{
              backgroundImage: `url('https://cdn.discordapp.com/avatars/${userProfile.userId}/${userProfile.avatarHash}.png`,
            }}
          />
          <div className="title profile">{userProfile.username}</div>
        </div>
      )}
      <DeleteAccount config={config} />
      <div className="tagline">
        {tradeStatus && userProfile.isVisible && (
          <div className="trade-status">{tradeStatus}</div>
        )}
        {userProfile.isVisible && !tradeStatus && (
          <div
            className={`content-container ${
              userProfile.isVisible ? "visible" : ""
            }`}
          >
            <input
              ref={inputRef}
              type="text"
              placeholder="Enter #order"
              className="order-input"
              value={orderNumber}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              maxLength={10}
              autoFocus
            />
          </div>
        )}
      </div>
      {showWarningMsg && (
        <WarningMsg
          onConfirm={() => handleUserConfirmation(true)}
          onCancel={() => handleUserConfirmation(false)}
        />
      )}
      <div className="checkbox-container">
        <div className="subtext">Powered by&nbsp;</div>
        <a
          href={config.haggleDiscordServer}
          target="_blank"
          rel="noopener noreferrer"
          className="haggle-discord"
        >
          haggle.gg
        </a>
      </div>
      <button
        className={`connect-button sign-button ${buttonState}`}
        onClick={() => {
          if (buttonState === "ready") {
            trySignPrompt();
          } else if (buttonState === "error") {
            navigate("/");
          } else if (buttonState === "cancel") {
            tryCancelPrompt();
          }
        }}
      >
        {buttonState === "ready"
          ? "Sign Transaction"
          : buttonState === "waiting"
          ? "Waiting..."
          : buttonState === "cancel"
          ? "Cancel Order" 
          : "Return"}
      </button>
    </div>
  );
}

export default Sign;
