// WelcomeMsg.js
import React, { useState } from 'react';
import Modal from './Modal';

const WelcomeMsg = ({ onConfirm, onCancel }) => {
  const [isModalOpen, setModalOpen] = useState(true); 

  // Function to close the modal and handle confirmation
  const handleConfirm = () => {
    setModalOpen(false);
    onConfirm(); 
  };

  // Function to close the modal and handle cancellation
  const handleClose = () => {
    setModalOpen(false);
    onCancel(); 
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleClose} 
          onConfirm={handleConfirm}
          prompt={`This demo app is provided "as is" without warranty and is very much a work in progress. Unforeseen bugs may lead to the loss of digital items and tokens, so please proceed with caution and use it at your risk.`}
          confirmText={"Acknowledge"}
        />
      )}
    </>
  );
};

export default WelcomeMsg;
