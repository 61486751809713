// WarningMsg.js
import React, { useState } from 'react';
import Modal from './Modal';

const WarningMsg = ({ onConfirm, onCancel }) => {
  const [isModalOpen, setModalOpen] = useState(true); 

  // Function to close the modal and handle confirmation
  const handleConfirm = () => {
    setModalOpen(false);
    onConfirm(); 
  };

  // Function to close the modal and handle cancellation
  const handleClose = () => {
    setModalOpen(false);
    onCancel(); 
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleClose} 
          onConfirm={handleConfirm}
          prompt={`🚧 Notice 🚧\nJust reject the transaction if it says it'll fail. It means you must approve the token/amount first.`}
          confirmText={"Understood"}
        />
      )}
    </>
  );
};

export default WarningMsg;
