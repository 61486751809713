// DeleteAccount.js
import React, { useState } from "react";
import { useDisconnect, useAccount } from "wagmi";
import Modal from "./Modal";

import XMarkIcon from "./img/xmark.svg";

const DeleteAccount = ({ config }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [promptMsg, setPromptMsg] = useState("");
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  // Function to open the modal
  const handleOpenModal = () => {
    if (isConnected) {
      disconnect();
      setPromptMsg(
        "Wallet disconnected. Are you sure you want to delete your login data? Wallet addresses are not stored."
      );
    } else {
      setPromptMsg(
        "Are you sure you want to delete your login data? Wallet addresses are not stored."
      );
    }
    setModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Function to confirm the deletion
  const handleAccountDeletion = () => {
    const deleteUrl = `${config.serverUrl}/delete-account`;

    fetch(deleteUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Problem with the deletion process");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Deletion successful", data);
        alert("Deletion successful.");

        // Clear localStorage and sessionStorage
        localStorage.clear();
        sessionStorage.clear();

        // Clear all cookies
        document.cookie.split(";").forEach((c) => {
          document.cookie =
            c.trim().split("=")[0] +
            "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        });

        window.location.href = "/";
        handleCloseModal(); // Close the modal after successful deletion
      })
      .catch((error) => {
        console.error("Error deleting account:", error);
        alert(
          "There was an error connecting to the server and deleting data. Please try again later."
        );
        // Keep the modal open or handle the error state as required
      });

    setModalOpen(false);
  };

  return (
    <>
    <div className="delete-button-container">
      <button
        className="delete-button"
        onClick={handleOpenModal} // Open the modal
        aria-label="Disconnect account"
      >
        <img src={XMarkIcon} alt="Delete" />
      </button>
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleAccountDeletion}
          prompt={promptMsg}
          confirmText={"Confirm"}
        />
      )}
    </>
  );
};

export default DeleteAccount;
