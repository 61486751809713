import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Global.css";
import "../styles/HaggleProfile.css";
import "../styles/Homepage.css";
import "../styles/CheckboxWrapper.css";

function RedirectAuth({ config }) {
  const navigate = useNavigate();
  const [buttonState, setButtonState] = useState("");
  const [authMessage, setAuthMessage] = useState("");
  const [authAttempted, setAuthAttempted] = useState(false);

  useEffect(() => {
    const processAuth = async () => {
      if (authAttempted) {
        return;
      }

      setAuthAttempted(true);

      const code = new URLSearchParams(window.location.search).get("code");

      if (!code) {
        setAuthMessage("No auth code provided.");
        navigate("/");
        return;
      }

      try {
        const fetchUrl = `${config.serverUrl}${config.serverRedirect}?code=${code}`;
        const response = await fetch(fetchUrl, {
          method: 'GET', 
          credentials: 'include' // Include credentials for cross-origin requests
        });
      
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      
        const data = await response.json();
        console.log("Success:", data);
      
        setAuthMessage(data.message);
        // setButtonState("success");
        navigate("/");
      } catch (error) {
        console.error("Error:", error);
        setAuthMessage("Authentication failed.");
        setButtonState("error");
      }      
    };

    processAuth();
  }, [navigate, config, authAttempted]);

  return (
    <div
      className={`content-container ${buttonState ? "visible" : ""}`}
      style={{
        animationName: buttonState ? "fastBounceIn" : "none",
        animationDuration: "0.5s",
        animationFillMode: "both",
      }}
    >
      <p className="tagline">{authMessage || "Awaiting authentication..."}</p>
      <button
        className={`connect-button return-button ${buttonState}`}
        onClick={() => navigate("/")}
      >
        Return
      </button>
    </div>
  );
}

export default RedirectAuth;
