import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Global.css";
import "../styles/HaggleProfile.css";
import "../styles/Homepage.css";
import "../styles/CheckboxWrapper.css";

function AuthPage({ config }) {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  const handleConnectDiscord = () => {
    const isChecked = document.getElementById("terms-checkbox").checked;
    if (!isChecked) {
      const wrapper = document.querySelector(".checkbox-wrapper");
      wrapper.classList.remove("shake");
      void wrapper.offsetWidth;
      wrapper.classList.add("shake");
    } else {
      window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${config.clientId}${config.discordRedirect}`;
    }
  };

  const handleLinkClick = (e, url) => {
    e.preventDefault();
    window.open(url, '_blank');
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div
      className={`content-container ${isVisible ? "visible" : ""}`}
      style={{
        animationName: "fastBounceIn",
        animationDuration: "0.5s",
        animationFillMode: "both",
      }}
    >
      <div
        className="display-image haggle-image"
        style={{
          backgroundImage: `url(${config.hagglelogo})`,
          filter: "grayscale(0%)",
        }}
      />
      <div className="title haggle">haggle.gg</div>
      <p className="tagline">The web3 barter bot.</p>
      <div className="checkbox-wrapper">
        <div className="checkbox-container">
          <input type="checkbox" id="terms-checkbox" />
          <label htmlFor="terms-checkbox">
              I agree to the{" "}
              <a
                href={`${config.clientUrl}${config.termsUrl}`}
                onClick={(e) => handleLinkClick(e, `${config.clientUrl}${config.termsUrl}.pdf`)}
              >
                terms
              </a>{" "}
              and&nbsp;
              <a
                href={`${config.clientUrl}${config.policyUrl}`}
                onClick={(e) => handleLinkClick(e, `${config.clientUrl}${config.policyUrl}.pdf`)}
              >
                privacy policy
              </a>.
            </label>
        </div>
      </div>
      <button
        className="connect-button discord-button"
        onClick={handleConnectDiscord}
      >
        Connect Discord
      </button>
    </div>
  );
}

export default AuthPage;
