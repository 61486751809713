import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useOpenConnectModal } from "@0xsequence/kit";
import { useDisconnect, useAccount } from "wagmi";

import { checkAuthentication } from "../functions/authService";
import DeleteAccount from "./DeleteAccount";
import WelcomeMsg from "./WelcomeMsg";
import "../styles/Global.css";
import "../styles/UserProfile.css";
import "../styles/DeleteProfile.css";
import "../styles/Homepage.css";

function Homepage({ config }) {
  const navigate = useNavigate();
  const [authCheckInProgress, setAuthCheckInProgress] = useState(true);
  const [userProfile, setUserProfile] = useState({
    userId: "",
    avatarHash: "",
    username: "",
    isVisible: false,
  });
  const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
  const { setOpenConnectModal } = useOpenConnectModal();
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  const applyStyles = useCallback((userId, avatarHash, username) => {
    setUserProfile({ userId, avatarHash, username, isVisible: true });
  }, []);

  const getAndApplyStyles = useCallback(() => {
    const userProfileUrl = `${config.serverUrl}/user-profile`;

    fetch(userProfileUrl, { credentials: "include" })
      .then((response) => response.json())
      .then((userProfile) => {
        applyStyles(
          userProfile.userId,
          userProfile.avatarHash,
          userProfile.username
        );
      })
      .catch((error) => console.error("Error fetching user profile:", error));
  }, [config.serverUrl, applyStyles]);

  const authCheckDoneRef = useRef(false);
  useEffect(() => {
    if (!authCheckDoneRef.current) {
      checkAuthentication(config, navigate, getAndApplyStyles).finally(() => {
        setAuthCheckInProgress(false); // Auth check is done
      });
      authCheckDoneRef.current = true;
    }
  }, [config, navigate, getAndApplyStyles]);

  // Effect to navigate when isConnected becomes true and authed
  useEffect(() => {
    if (isConnected && !authCheckInProgress) {
      navigate("/sign");
    }
  }, [isConnected, authCheckInProgress, navigate]);

  const handleConnect = async () => {
    setShowWelcomeMsg(true);
  };

  // Function to handle user confirmation from the welcome message
  const handleUserConfirmation = async (confirm) => {
    setShowWelcomeMsg(false); // Close the welcome message modal
    if (confirm) {
      try {
        setOpenConnectModal(true); // Proceed with wallet connection only if confirmed
      } catch (e) {
        console.error("Failed to connect wallet:", e);
      }
    }
  };

  const handleDisconnect = async () => {
    try {
      disconnect();
    } catch (e) {
      console.error("Failed to disconnect wallet:", e);
    }
  };

  const buttonClassName = `connect-button wallet-button ${
    isConnected ? "disconnect" : "connect"
  }`;

  return (
    <div
      className={`content-container ${userProfile.isVisible ? "visible" : ""}`}
    >
      {userProfile.userId && (
        <div className="profile-container">
          <div
            className="display-image profile-image"
            style={{
              backgroundImage: `url('https://cdn.discordapp.com/avatars/${userProfile.userId}/${userProfile.avatarHash}.png`,
            }}
          />
          <div className="title profile">{userProfile.username}</div>
        </div>
      )}
      <DeleteAccount config={config} />
      <p className="tagline">
        {userProfile.username ? `Welcome 😸! ` : "Please log in."}
      </p>
      {showWelcomeMsg && (
        <WelcomeMsg
          onConfirm={() => handleUserConfirmation(true)}
          onCancel={() => handleUserConfirmation(false)}
        />
      )}
      <div className="checkbox-container">
        <div className="subtext">Powered by&nbsp;</div>
        <a
          href={config.haggleDiscordServer}
          target="_blank"
          rel="noopener noreferrer"
          className="haggle-discord"
        >
          haggle.gg
        </a>
      </div>
      <button
        className={buttonClassName}
        onClick={isConnected ? handleDisconnect : handleConnect}
      >
        {isConnected ? "Disconnect Wallet" : "Connect Wallet"}
      </button>
    </div>
  );
}

export default Homepage;
